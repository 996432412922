import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/article.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hello  Readers!`}</p>
    <p>{`Welcome to a brand new year and a brand new edition of the AFPI newsletter! The year that passed by has been very exciting for AFPI Karnataka as we hosted the most awaited event – FMPC 2019 in August at JN Tata  Auditorium, IISc campus. The mega event saw participation of over 700 delegates from all over the world along with eminent personalities from the RGUHS, NBE, BBMP, NHM and IMA. Family medicine and AFPI was brought to the forefront of the media and the health department  too through this conference.  We sincerely thank each and every delegate who came and graced this event and made it a grand success. We look forward to hosting many more such events and engaging all those who are part of this movement of taking family medicine into the core of the country’s healthcare.`}</p>
    <p>{`This edition is extremely special in that it’s conceived and compiled by the young energetic new team of editors headed by Dr Akshay! I wish them all the best and hope to see further newsletters of high caliber  with the involvement of primary care physicians  from all  parts of the state and country. Hope this new edition sets the trend for it !`}</p>
    <p>{`We sincerely request all of you to contribute your valuable thoughts in the form of case reports, original research, medical updates etc.`}</p>
    <p>{`Once again wishing you all a great year ahead…`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      